import { Container } from "./styles";
import { toast } from "react-toastify";

export default function Dropdown() {
  return (
    <Container>
      <span
        onClick={() =>
          // window.open("", "_blank")
          toast.info("Em desenvolvimento")
        }
      >
        Projetos geração centralizada
      </span>
      <span
        onClick={() =>
          // window.open("", "_blank")
          toast.info("Em desenvolvimento")
        }
      >
        Projetos geração distruibuída
      </span>
      <span
        onClick={() =>
          // window.open("", "_blank")
          toast.info("Em desenvolvimento")
        }
      >
        Seja um investidor
      </span>
      <span
        onClick={() =>
          // window.open("", "_blank")
          toast.info("Em desenvolvimento")
        }
      >
        Sobre
      </span>
      <span
        onClick={() =>
          // window.open("", "_blank")
          toast.info("Em desenvolvimento")
        }
      >
        Conteúdos
      </span>
      <span
        onClick={() =>
          // window.open("", "_blank")
          toast.info("Em desenvolvimento")
        }
      >
        Conecte-se
      </span>
    </Container>
  );
}
