import React, { useState, ChangeEvent, FormEvent } from "react";
import {
  ContactForm,
  Container,
  ContentLeft,
  ContentRight,
  Input,
  InputGroup,
  SendBtn,
  Text,
  TextArea,
  TitleRight,
} from "./styles";
import { toast } from "react-toastify";

interface InvestorProps {}

const Investor: React.FC<InvestorProps> = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!name.trim() || !email.trim() || !message.trim()) {
      toast.error("Por favor, preencha todos os campos");
      return;
    }

    if (!validateEmail()) {
      toast.error("E-mail inválido");
      return;
    }

    // Restante da lógica para enviar o formulário, se necessário
  };

  return (
    <Container>
      <ContentLeft>
        <Text>
          Você sabia que investir em <strong>energia sustentável</strong> é uma
          <strong> maneira inteligente</strong> de proteger o planeta e seu
          patrimônio?
        </Text>
      </ContentLeft>

      <ContentRight>
        <TitleRight>
          <h2>Invista agora!</h2>
          <span>Entre em contato</span>
        </TitleRight>

        <ContactForm onSubmit={handleSubmit}>
          <InputGroup>
            <Input
              placeholder="Nome"
              type="text"
              value={name}
              onChange={handleNameChange}
            />
            <Input
              placeholder="E-mail"
              type="text"
              value={email}
              onChange={handleEmailChange}
            />
          </InputGroup>

          <TextArea
            placeholder="Mensagem"
            value={message}
            onChange={handleMessageChange}
          />
          <SendBtn type="submit">Enviar</SendBtn>
        </ContactForm>
      </ContentRight>
    </Container>
  );
};

export default Investor;
