import styled from "styled-components";
import {
  InstagramAlt,
  LinkedinSquare,
  Twitter,
} from "styled-icons/boxicons-logos";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 4px solid white;
  padding-top: 2rem;
  padding-bottom: 2rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: #ffff;
  position: relative;
`;

export const LeftGroup = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

export const RightGroup = styled(LeftGroup)`
  justify-content: space-evenly;
`;

export const Logo = styled.img`
  width: 15rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TwitterIcon = styled(Twitter)`
  color: #ffff;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  :hover {
    color: #ffc700;
  }
`;

export const InstagramIcon = styled(InstagramAlt)`
  color: #ffff;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  :hover {
    color: #ffc700;
  }
`;

export const LinkedinIcon = styled(LinkedinSquare)`
  color: #ffff;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  :hover {
    color: #ffc700;
  }
`;

export const DeveloperInfo = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: end;
  padding-right: 1rem;
`;

export const DeveloperName = styled.span`
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  :hover {
    color: #ffc700;
    text-decoration: underline;
  }
`;
