import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffc70080 50%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1.5rem;
  margin-top: 4.5rem;
  color: #ffff;
  width: 25rem;
  span {
    width: 100%;
    padding: 0 0.4rem;
    border-left: 4px solid #ffff;
    border-right: 4px solid #ffff;
    border-bottom: 4px solid #ffff;
    text-transform: uppercase;
    /* font-family: "Greycliff CF"; */
    font-style: normal;
    font-weight: 900;
    font-size: 1.1rem;
    line-height: 30px;
    cursor: pointer;
    :first-child {
      border: 4px solid #ffff;
    }
  }
`;
