import styled from "styled-components";
import { Menu } from "@styled-icons/boxicons-regular/Menu";
import {
  InstagramAlt,
  LinkedinSquare,
  Twitter,
} from "styled-icons/boxicons-logos";
import { Home } from "styled-icons/boxicons-solid";

interface HeaderProps {
  isHeaderVisible: boolean;
}

export const Container = styled.div<HeaderProps>`
  width: 100%;
  height: 5rem;
  display: ${(props) => (props.isHeaderVisible ? "flex" : "none")};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  border-top: 4px solid #ffff;
  border-bottom: 4px solid #ffff;
  padding: 0 1.5rem;
  position: fixed;
  z-index: 1;
  user-select: none;
  opacity: ${(props) => (props.isHeaderVisible ? 1 : 0)};
  transition: opacity 0.3s ease; // Adicione a propriedade transition para obter uma transição suave
`;

export const GroupLeft = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const Options = styled.div`
  width: 4.5rem;
  height: 100%;
  background-color: #ffc700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 4px solid #ffff;
  border-right: 4px solid #ffff;
  cursor: pointer;
`;

export const MenuIcon = styled(Menu)`
  width: 80%;
  height: 80%;
  color: #ffff;
`;

export const Logo = styled.img`
  width: 10rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GroupRigth = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.1rem;
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 26px;
    text-transform: uppercase;
    color: #ffffff;
  }
`;

export const TwitterIcon = styled(Twitter)`
  color: #ffff;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  :hover {
    color: #ffc700;
  }
`;

export const InstagramIcon = styled(InstagramAlt)`
  color: #ffff;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  :hover {
    color: #ffc700;
  }
`;

export const LinkedinIcon = styled(LinkedinSquare)`
  color: #ffff;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  :hover {
    color: #ffc700;
  }
`;

export const HomeIcon = styled(Home)`
  color: #ffff;
  width: 2rem;
  height: 2rem;
  margin-left: 3rem;
  cursor: pointer;
  :hover {
    color: #ffc700;
  }
`;
