import React from "react";
import { Banner, Container, WrapperBanner } from "./styles";
import Header from "../../Components/Header";
import Investor from "../../Components/Investor";
import Footer from "../../Components/Footer";
// import ListMenu from "../../Components/ListMenu";

const Home: React.FC = () => {
  return (
    <Container>
      <Header />
      <WrapperBanner>
        <Banner />
      </WrapperBanner>
      {/* <ListMenu /> */}
      <Investor />
      <Footer />
    </Container>
  );
};

export default Home;
