import {
  Container,
  DeveloperInfo,
  DeveloperName,
  InstagramIcon,
  LeftGroup,
  LinkedinIcon,
  Logo,
  RightGroup,
  TwitterIcon,
} from "./styles";
import LogoWhite from "../../assets/images/ecowatts-white.svg";

export default function Footer() {
  const CurrentYear = new Date().getFullYear();

  const InstagramURL = "https://www.instagram.com/ecowatts_eco/";
  const TwitterURL =
    "https://twitter.com/ecowatts_eco?s=21&t=pg_XSJoSldK6uETuRl86Dg";
  const LinkedinURL = "https://www.linkedin.com/company/ecowattsenergia";
  const LinkedinDeveloperURL = "https://www.linkedin.com/in/rodrigo-vilemen/";

  return (
    <Container>
      <LeftGroup>
        <Logo src={LogoWhite} alt="EcoWatts Energia" />
        <div>{`Copyright © ${CurrentYear} Ecowatts, Ltda.`}</div>
      </LeftGroup>

      <RightGroup>
        <div>Acesse nossas redes:</div>
        <div>
          <TwitterIcon onClick={() => window.open(TwitterURL, "_blank")} />
          <InstagramIcon onClick={() => window.open(InstagramURL, "_blank")} />
          <LinkedinIcon onClick={() => window.open(LinkedinURL, "_blank")} />
        </div>
      </RightGroup>

      <DeveloperInfo>
        Desenvolvido por{" "}
        <DeveloperName
          onClick={() => window.open(LinkedinDeveloperURL, "_blank")}
        >
          R.Vilemen - Software
        </DeveloperName>
        .
      </DeveloperInfo>
    </Container>
  );
}
