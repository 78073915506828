import { useEffect, useState } from "react";
import {
  Container,
  Options,
  MenuIcon,
  Logo,
  GroupLeft,
  GroupRigth,
  TwitterIcon,
  InstagramIcon,
  LinkedinIcon,
  HomeIcon,
} from "./styles";
import Dropdown from "../Dropdown";
import LogoWhite from "../../assets/images/ecowatts-white.svg";
import useHeight from "../../utils/functions/verifyHeight";

export default function Header() {
  const InstagramURL = "https://www.instagram.com/ecowatts_eco/";
  const TwitterURL =
    "https://twitter.com/ecowatts_eco?s=21&t=pg_XSJoSldK6uETuRl86Dg";
  const LinkedinURL = "https://www.linkedin.com/company/ecowattsenergia";

  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  const headerHeight = 28; // Definir a altura que o header deve sumir em rem
  const parseToRem = headerHeight * 16;
  const isHeaderVisible1 = useHeight(parseToRem);

  useEffect(() => {
    if (isHeaderVisible1) {
      setDropdownVisible(false);
    }
  }, [isHeaderVisible1]);

  return (
    <Container isHeaderVisible={isHeaderVisible1}>
      <GroupLeft>
        <Options onClick={() => setDropdownVisible(!dropdownVisible)}>
          <MenuIcon />
        </Options>
        {dropdownVisible && <Dropdown />}
        <Logo src={LogoWhite} alt="EcoWatts Energia" />
      </GroupLeft>

      <GroupRigth>
        <span>Acesse nossas redes:</span>
        <TwitterIcon onClick={() => window.open(TwitterURL, "_blank")} />
        <InstagramIcon onClick={() => window.open(InstagramURL, "_blank")} />
        <LinkedinIcon onClick={() => window.open(LinkedinURL, "_blank")} />
        <HomeIcon />
      </GroupRigth>
    </Container>
  );
}
