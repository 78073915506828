import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  align-items: center;
  color: #ffff;
`;

export const ContentLeft = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6.5rem;
`;

export const Text = styled.p`
  font-size: 2.375rem;
  flex-wrap: wrap;
`;

export const ContentRight = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Input = styled.input`
  width: 40%;
  border: 4px solid #ffffff;
  background-color: transparent;
  padding: 1rem;
  color: #ffffff;
  :first-child {
    border-right: none;
  }
  ::placeholder {
    color: #ffffff;
  }
`;

export const TextArea = styled.textarea`
  width: 80%;
  height: 10rem;
  border: 4px solid #ffffff;
  border-top: none;
  border-bottom: none;
  background-color: transparent;
  padding: 1rem;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  ::placeholder {
    color: #ffffff;
  }
`;

export const SendBtn = styled.button`
  width: 80%;
  padding: 0.5rem;
  color: #ffffff;
  background-color: transparent;
  border: 4px solid #ffffff;
  cursor: pointer;
  transition: 1s;
  :hover {
    background: linear-gradient(
      90deg,
      #ffae00 47.41%,
      #ff6500 65.18%,
      #ff2e00 90.84%
    );
  }
`;

export const TitleRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 1.2rem;
`;
